import React, { useEffect, useRef, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { Container, Grid, GridItem, Heading } from '@charlietango/ui';
import { rem } from 'polished';

import { useUser } from '../../api/hooks/useUser';
import ArticleSection from '../../components/ArticleSection/ArticleSection';
import ArticleSectionViewModel from '../../components/ArticleSection/types/ArticleSectionViewModel';
import DeclarationOverview from '../../components/DeclarationOverview/DeclarationOverview';
import { DeclarationOverviewViewModel } from '../../components/DeclarationOverview/types/DeclarationOverviewViewModel';
import HowToSection from '../../components/HowToSection/HowToSection';
import InitiateFrontPage from '../../components/InitiateFrontPage/InitiateFrontPage';
import Logo from '../../components/Logo/Logo';
import Placeholder from '../../components/Placeholder/Placeholder';
import RichText from '../../components/RichText/RichText';
import { TextVariant } from '../../styles/typography';
import AccordionViewModel from '../../view-models/AccordionViewModel';
import TextBlockViewModel from '../../view-models/TextBlockViewModel';
import FrontPageInitiateDeclarationViewModel from './types/FrontPageInitiateDeclarationViewModel';

export type FrontPageProps = {
  intro?: TextBlockViewModel;
  declarationOverview?: DeclarationOverviewViewModel;
  initiateDeclaration?: FrontPageInitiateDeclarationViewModel;
  howTo?: AccordionViewModel;
  articleSections?: ArticleSectionViewModel[];
};

export function FrontPage({
  intro,
  declarationOverview,
  initiateDeclaration,
  howTo,
  articleSections,
}: FrontPageProps) {
  const { user, userReady } = useUser();
  const ref = useRef<HTMLDivElement>(null);
  const [offsetTop, setOffsetTop] = useState('0');

  useEffect(() => {
    const onResize = () => {
      if (ref.current) {
        // Get the actual padding
        const paddingTop = getComputedStyle(ref.current).paddingTop;
        setOffsetTop(`calc(${ref.current.offsetTop}px + ${paddingTop} - 1rem)`);
      }
    };

    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <Container ref={ref} sx={{ pt: ['2rem', '4rem', '8rem'], pb: '8rem' }}>
      <Grid>
        <GridItem size={[1, 1 / 2, 5 / 12]}>
          <section
            style={{ top: offsetTop }}
            sx={{
              position: [null, 'sticky'],
              // The top offset should be based on the outer padding and offsetTop
              mt: [null, '-1rem'],
            }}
          >
            <Logo animateIn sx={{ width: rem(70), height: rem(70), mb: 5 }} />
            <Heading as="h1" variant={TextVariant.H1} sx={{ mb: 6 }}>
              {intro?.title}
            </Heading>
            <RichText
              sx={{ mb: ['2rem', null, null, rem(56)] }}
              variant={TextVariant.Paragraph}
            >
              {intro?.text}
            </RichText>
            {initiateDeclaration && (
              <InitiateFrontPage
                model={initiateDeclaration}
                user={user}
                userReady={userReady}
                {...initiateDeclaration}
              />
            )}
          </section>
        </GridItem>
        <GridItem size={[null, null, 1 / 12]} />
        <GridItem size={[1, 1 / 2]}>
          {userReady ? (
            user ? (
              <DeclarationOverview
                user={user}
                sx={{ mb: [9, null, rem(56)] }}
                {...declarationOverview}
              />
            ) : (
              howTo && (
                <HowToSection
                  title={howTo.title}
                  items={howTo.items}
                  sx={{ mb: [9, null, rem(56)] }}
                />
              )
            )
          ) : (
            <Placeholder
              css={{
                // This is the expected height of the to `<HowToSection>`, if the user hasn't applied any text zooming
                height: 338,
              }}
            />
          )}
          {articleSections &&
            articleSections.map((section, i) => (
              <React.Fragment key={i}>
                {i !== 0 && <div sx={{ pb: rem(32) }} />}
                <ArticleSection
                  gridColumns={[1, 1, 1, 2]}
                  itemSpan={[1, 1, 1, 1 / 2]}
                  {...section}
                />
              </React.Fragment>
            ))}
        </GridItem>
      </Grid>
    </Container>
  );
}

FrontPage.displayName = 'FrontPage';

export default !process.env.HOT ? FrontPage : hot(FrontPage);
