import React from 'react';
import { Text } from '@charlietango/ui';
import { rem } from 'polished';

import { SchemaFrontendUserDataViewModel } from '../../api/api-schema';
import { useAuth } from '../../api/AuthProvider';
import FrontPageInitiateDeclarationViewModel from '../../modules/FrontPage/types/FrontPageInitiateDeclarationViewModel';
import { ButtonVariant } from '../../styles/buttons';
import { Colors } from '../../styles/colors';
import { TextVariant } from '../../styles/typography';
import Anchor from '../Anchor/Anchor';
import Arrow from '../Arrow/Arrow';
import Button from '../Button/Button';

type Props = {
  model?: FrontPageInitiateDeclarationViewModel;
  user?: SchemaFrontendUserDataViewModel;
  userReady?: boolean;
};

function InitiateFrontPage({ model, user, userReady }: Props) {
  const { loginUrl } = useAuth();

  if (
    // Wait with showing the button, until we validated the current user state
    !userReady ||
    // We should not invite the user to start the flow, if they already have added declarations.
    !!user?.userDeclarations?.length ||
    // Should not invite them to if they don't have voting rights
    (user && !user?.votingRights?.dk && !user?.votingRights?.eu)
  ) {
    return null;
  }

  return (
    <div
      sx={{
        position: 'relative',
      }}
    >
      {model?.link && (
        <Button
          variant={ButtonVariant.Primary}
          icon={<Arrow />}
          {...model.link}
        >
          {model.link?.label}
        </Button>
      )}
      <div
        sx={{
          mt: 6,
          mb: [7, 0],
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {!user && (
          <Text variant={TextVariant.Paragraph}>
            {model?.alreadyDeclaredText}
            {model?.loginLabel && (
              <Anchor
                external
                prefetch={false}
                sx={{
                  ml: '5px',
                  fontSize: rem(14),
                  lineHeight: 1.71,
                  transition: 'color .3s ease',
                  color: Colors.Secondary,
                  '&:hover': {
                    color: Colors.Black,
                  },
                }}
                label={model.loginLabel}
                href={loginUrl}
              />
            )}
          </Text>
        )}
      </div>
    </div>
  );
}

export default InitiateFrontPage;
